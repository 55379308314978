// src/utils/dateUtils.js

export function formatDateToUTC(date, forAPI = false) {
    if (!date) return ""; // Handle null or undefined
    const d = new Date(date);
    const pad = (num) => String(num).padStart(2, '0'); // Helper function to pad numbers

    if (forAPI) {
        // Format as UTC time with "Z" to indicate UTC
        const yearUTC = d.getUTCFullYear();
        const monthUTC = pad(d.getUTCMonth() + 1); // Months are zero-indexed
        const dayUTC = pad(d.getUTCDate());
        const hoursUTC = pad(d.getUTCHours());
        const minutesUTC = pad(d.getUTCMinutes());
        const secondsUTC = pad(d.getUTCSeconds());
        const millisecondsUTC = String(d.getUTCMilliseconds()).padStart(3, '0'); // Pad milliseconds to 3 digits
        return `${yearUTC}-${monthUTC}-${dayUTC}T${hoursUTC}:${minutesUTC}:${secondsUTC}.${millisecondsUTC}Z`;
    } else {
        // Format as local system time
        const year = d.getFullYear();
        const month = pad(d.getMonth() + 1); // Months are zero-indexed
        const day = pad(d.getDate());
        const hours = pad(d.getHours());
        const minutes = pad(d.getMinutes());
        const seconds = pad(d.getSeconds());
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Local time in readable format
    }
}

