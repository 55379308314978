import React from 'react';
import './ServiceSection.css';
import integrationsolution from '../../Resource/IntegrationSolution.jpg'; // Replace with your image
import ComprehensiveResourcing from '../../Resource/ComprehensiveResourcing.jpg'; // Replace with your image
import AutomationTools from '../../Resource/AutomationTools.jpg'; // Replace with your image
import Title from '../Title/Title';
import { Link } from 'react-router-dom';
import TruncatedText from '../../ProHelpers/TruncatedText'

const ServiceSection = () => {
  const serviceIntegration = "Unlock the full potential of your SAP environment with our SAP Integration Solutions, enabling seamless integration between SAP and other enterprise systems for smooth data flow and improved efficiency";
  const serviceAutomation = "Discover the power of automation with our cutting-edge tools designed to streamline your daily operations. Our automation solutions help you optimize workflows, reduce manual tasks, and enhance productivity across various business processes. From automated system monitoring and performance tracking to intelligent error detection and reporting, our tools provide you with real-time insights and proactive alerts.";
  const serviceComprehensive = "At Innodhee, we understand that finding the right talent is crucial for your business's success. Our Comprehensive Resourcing services are designed to streamline the recruitment process, ensuring you have access to the best candidates for your needs.";

  return (
    <section className="service-section">
      <div className="service-container">
        <div className="service-card">
          <div className="service-image">
            <img src={integrationsolution} alt="Service 1" />
          </div>
          <div className="service-text">
            <h4>Integration Solutions</h4>
            <TruncatedText text={serviceIntegration} limit={90} />
            <a href="#contact-section"><button className="service-btn">Connect With Us</button></a>
          </div>
        </div>
        <div className="service-card">
          <div className="service-image">
            <img src={AutomationTools} alt="Service 2" />
          </div>
          <div className="service-text">
            <h4>Automation Tools</h4>
            <TruncatedText text={serviceAutomation} limit={90} />
            <a href="#contact-section"><button className="service-btn">Connect With Us</button></a>
          </div>
        </div>
        <div className="service-card">
          <div className="service-image">
            <img src={ComprehensiveResourcing} alt="Service 3" />
          </div>
          <div className="service-text">
            <h4>Comprehensive Resourcing</h4>
            <TruncatedText text={serviceComprehensive} limit={90} />
            <a href="#contact-section"><button className="service-btn">Connect With Us</button></a>
          </div>
        </div>
      </div>
    </section >
  );
};

export default ServiceSection;
