import React from 'react';
import './header.css';

import Nav from './../Dashboard/Nav/Nav'
import brandImg from '../../Resource/logo.png';
import Logo from './../Dashboard/Logo';

function Header() {
    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="logo-container">
                <Logo />
                <img src={brandImg} alt="Brand Logo" className="brand-img" />
            </div>
            <Nav />
        </header>
    );
}

export default Header;
