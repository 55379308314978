import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer id="footer" className="dashboard-footer">
      <div className="copyright">
        &copy; Copyright{' '}
        <span>INNODHEE SERVICES PRIVATE LIMITED</span>
        . All Rights Reserved
      </div>
      <div className="credits">
        Designed by <a href="/" target="_blank">Innodhee Services</a>
      </div>
    </footer>
  );
}

export default Footer;
