import React, { useState } from 'react'
import './ForgotPassword.css';
import forgotImage from '../../Resource/forgotpassword.png'; // Add your image here
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../ProHelpers/AlertContext'; // Import the useAlert hook
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import Logo from '../../ProHelpers/Logo'

function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [email, setEmail] = useState(''); // State for email

    // Function to handle input change
    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const ForgotEmail = async (email) => {
        try {
            const requestBody = {
                EmailID: email
            };
            setLoading(true);
            setMessage("Please Wait, Logging...")
            const response = await AxiosInstance.post('/ForgotPassword', requestBody);
            setLoading(false);
            const { message, status } = response.data || {};
            if (status === "success") {
                console.log("Verification: Response message ", message)
                setEmail('')
                setTimeout(() => showAlert(message, status), 0);
                //navigate('/Dashboard', { state: { message, reason } });
            } else {
                console.log("Verification: Response message ", message);
                setTimeout(() => showAlert(message, 'failure'), 0);
            }
        } catch (error) {
            setLoading(false);
            console.log("Error fetching data from API:", error);
            return { message: "error", reason: "API request failed" };
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission
        ForgotEmail(email); // Call the LoginApi function
    };

    return (
        <div>
            {loading && <LoadingPopup message={message} />}
            <div className="forgot-password-page d-flex justify-content-center align-items-center">
                <div className="forgotpassword-card shadow-lg">
                    <div className="forgotpassword-card-left d-none d-md-flex">

                        <Link to="/">
                            {/* <img src={logo} alt="Logo" className="logo" /> */}
                            <Logo className="forgotpasswordlogo" />
                        </Link>
                        <img src={forgotImage} alt="forgot password" className="forgotpassword-image" />
                    </div>
                    <div className="forgotpassword-card-right p-4">
                        <h2 className="forgotpassword-title text-left">Forgot Your Password?</h2>
                        <p className="text-left mb-4 forgotpassword-text">We get it, stuff happens. Just enter your email address</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-container mt-2">

                                <div className="forgotpassword-form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder="Enter your email"
                                        className="form-control"
                                        value={email}
                                        onChange={handleInputChange} // Update email state
                                        required />
                                </div>
                                <div className="forgotpassword-btn-container">
                                    <button type="submit" className="btn forgotpassword-btn">Reset Password</button>
                                </div>
                            </div>
                        </form>
                        <div className="forgotpassword-login-link text-end">
                            <p>Already have an account? <Link to="/register">Create account</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword

