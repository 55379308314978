import React, { useState } from 'react';
import profileImg from '../../../Resource/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import LoadingPopup from '../../../ProHelpers/LoadingPopup';

function NavAvatar() {
  const navigate = useNavigate();
  const UserName = sessionStorage.getItem('UserName');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Please wait, loading...");

  const handleSignOut = () => {

    // Clear session data
    sessionStorage.clear();
    sessionStorage.removeItem('UserName');
    // Redirect to login page
    navigate('/login');
  };


  return (
    <li className="nav-item dropdown pe-3">
      <a
        className="nav-link nav-profile d-flex align-items-center pe-0"
        href="#"
        data-bs-toggle="dropdown"
      >
        <img src={profileImg} alt="Profile" className="rounded-circle" />
        <span className="d-none d-md-block dropdown-toggle ps-2">{UserName}  </span>
      </a>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <li className="dropdown-header">
          <h6>{UserName}</h6>
          {/* <span>Web Developer</span> */}
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a
            className="dropdown-item d-flex align-items-center"
            href="users-profile.html"
          >
            <Link to="/userprofile" className="nav-link">
              <i className="bi bi-person"></i>
              <span>My Profile</span>
            </Link>

          </a>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a
            className="dropdown-item d-flex align-items-center"
            href="users-profile.html"
          >
            <i className="bi bi-gear"></i>
            <span>Account Settings</span>
          </a>
        </li>
        {/* <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a
            className="dropdown-item d-flex align-items-center"
            href="pages-faq.html"
          >
            <i className="bi bi-question-circle"></i>
            <span>Need Help?</span>
          </a>
        </li> */}
        <li>
          <hr className="dropdown-divider" />
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="#"
            onClick={handleSignOut}>
            <i className="bi bi-box-arrow-right"></i>
            <span>Sign Out</span>
          </a>
        </li>
      </ul>
    </li>
  );
}

export default NavAvatar;
