import React from 'react'
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';

function MonitoringFailure() {
    return (
        <>
            <Header />
            <SideBar />
            <div className="app-container">
                <main id="main" className="main">
                    <PageTitle title={"Monitoring Falure"} subtitle={"Message Overall"} />
                    <div>MonitoringFailure</div>
                </main>
            </div>
            <Footer />
            <BackToTop />
        </>


    )
}

export default MonitoringFailure