import React, { useEffect, useState } from 'react'
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import LoadingPopup from '../../ProHelpers/LoadingPopup';

function UserProfile() {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    return (
        <div>
            {loading ? (
                <LoadingPopup message={message} />
            ) : (
                <>
                    <Header />
                    <SideBar />
                    <div className="app-container">
                        <main id="main" className="main">
                            <PageTitle title={"Profile"} subtitle={"User Profile"} />
                            <div>User Profile</div>
                        </main>
                    </div>
                    <Footer />
                    <BackToTop />
                </>
            )
            }
        </div >
    )
}

export default UserProfile