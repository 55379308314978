import React, { useState, useEffect } from 'react';
import './UnderConstruction.css';

const UnderConstruction = () => {

    return (
        <div className="under-construction">
            <div className="container">
                <h1>🚧 Under Construction 🚧</h1>
                <p>Our website is getting a makeover! Check back soon.</p>
                <div className="countdown">

                </div>
                <p>We appreciate your patience and understanding!</p>
            </div>
        </div>
    );
};

export default UnderConstruction;
