import React from 'react';
import './pageTitle.css';

function PageTitle({ title, subtitle }) {
  return (
    <div className="pagetitle">
      <h1>{title}</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/OverView">
              <i className="bi bi-house-door"></i>
            </a>
          </li>
          <li className="breadcrumb-item active">{subtitle}</li>
        </ol>
      </nav>
    </div>
  );
}

export default PageTitle;
