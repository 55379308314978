import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { useNavigate, useLocation } from 'react-router-dom';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import './DetailsPage.css'
import './main.css';
import Select from 'react-select';
import Header from './Header';
import SideBar from './SideBar';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import backButtonImage from '../../Resource/dark-back-arrow.png'
import { formatDateToUTC } from '../../ProHelpers/DateUtils'


const DetailsPage = () => {
    const location = useLocation();
    const EmailID = sessionStorage.getItem('EmailID');
    const [messageOverallData, setMessageOverallData] = useState(null);
    const [header, setHeader] = useState(null);
    const [startEndTime, setStartEndTime] = useState(null);
    const [loading, setLoading] = useState(true);
    const [responseData, setresponseData] = useState([]);
    const [message, setMessage] = useState("Please wait, loading...");
    const [timeRange, setTimeRange] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [filteredData, setFilteredData] = useState([]);
    const [messageTypeFilter, setMessageTypeFilter] = useState(null);
    const [messageIDFilter, setMessageIDFilter] = useState(null);
    const [applicationIDFilter, setApplicationIDFilter] = useState(null);
    const [startFilter, setStartFilter] = useState(null);
    const [endFilter, setEndFilter] = useState(null);
    const [isCustomTimeZone, setIsCustomTimeZone] = useState(false); // Default to false
    const navigate = useNavigate(); // Hook for navigation

    const handleGoBackClick = () => {
        navigate('/monitoring/overall'); // Replace '/your-target-path' with the desired route
    };
    const handleSidebarItemClick = () => {
        // Optional: Clear localStorage if you want to remove the saved range
        //localStorage.removeItem("timeRange");

        // Reset the timeRange and date-time range
        setTimeRange("lastDay");
        calculateTimeRange("lastDay");
    };
    const calculateTimeRange = (selectedRange) => {
        const end = new Date(); // current date and time
        let start;

        switch (selectedRange) {
            case "lastHour":
                start = new Date(end.getTime() - 60 * 60 * 1000); // Subtract 1 hour in milliseconds
                break;
            case "lastDay":
                start = new Date(end.getTime() - 24 * 60 * 60 * 1000); // Subtract 1 day in milliseconds
                break;
            case "lastWeek":
                start = new Date(end.getTime() - 24 * 7 * 60 * 60 * 1000); // Subtract 1 day in milliseconds
                break;
            case "lastMonth":
                // Handle month subtraction properly and account for day overflow
                const previousMonth = new Date(end);
                previousMonth.setMonth(end.getMonth() - 1); // Move to the same day of the previous month
                if (previousMonth.getMonth() === end.getMonth()) {
                    // This means the day in the previous month doesn't exist; adjust to the last day
                    previousMonth.setDate(0);
                }
                start = new Date(previousMonth);
                break;
            case "thisMonth":
                start = new Date(end.getFullYear(), end.getMonth(), 1, 0, 0, 0, 0); // Start of the current month
                break;
            default:
                start = null;
                break;
        }
        setStartEndTime((prev) => ({
            ...prev,
            start: start ? start.toISOString() : null,
            end: end.toISOString(),
        }));
        if (selectedRange) {
            localStorage.setItem("timeRange", selectedRange);
        }
    };

    useEffect(() => {
        const checkCondition = () => {
            // Replace this logic with your actual condition
            const condition = false;
            setIsCustomTimeZone(condition);
        };

        checkCondition();
    }, []); // Runs only on component mount

    useEffect(() => {

        if (location.state) {
            const { messageOverallData, header, dateRange } = location.state;
            const startEndTime = dateRange?.startEndTime;
            console.log("Received date range:", startEndTime);
            console.log("Received Data range:", messageOverallData);
            console.log("Received Status range:", header);
            setMessageOverallData(messageOverallData);
            setHeader(header);
            setStartEndTime(startEndTime);
            const conditions = {
                'DetailsPage': true,
                "PackageName": messageOverallData.PackageName,
                "IFlowName": messageOverallData.IFlowName,
                "Status": header,
                "EmailID": EmailID,
                "End": startEndTime.end,
                "Start": startEndTime.start
            };
            fetchData(conditions, currentPage, pageSize);
        } else {
            // Handle the case where no data is passed
            setLoading(false); // Avoid infinite loading
        }
    }, [location.state]);

    const fetchData = async (condition = {}, Page, PageSize) => {
        const requestBody = { ...condition, Page, PageSize };
        setLoading(true);
        setMessage("Please Wait, Loading...");
        console.log("Request Body:", requestBody);

        try {
            const response = await AxiosInstance.post('/message-processinglog', requestBody);

            if (response.data.status === "success" && response.data.message && response.data.message.data.length > 0) {
                setresponseData(response.data.message.data);
                setFilteredData(response.data.message.data);

                // Assuming the server returns totalItems and pageSize
                setTotalRecords(response.data.message.total_records);
                setTotalPages(Math.ceil(response.data.message.total_records / pageSize));
            } else {
                setresponseData([]);
                setFilteredData([]);
                setTotalRecords(1);
                setTotalPages(1); // Set to 1 if no data is available
            }

            console.log('Response Data:', response.data);
            console.log('Response first Item:', response.data.message.data);
        } catch (err) {
            console.error('Error fetching data:', err);
            setresponseData([]);
            setFilteredData([]);
            setTotalPages(1);
        } finally {
            setLoading(false);
        }
    };


    // Unique dropdown options
    const getUniqueOptions = (key) => {
        return [...new Set(responseData.map(item => item[key]))]
            .filter(item => item) // remove empty values
            .map(item => ({ value: item, label: item }));
    };

    // Handle dropdown changes
    const handleFilterChange = (filter, setFilter) => (selectedOption) => {
        setFilter(selectedOption);
        applyFilters({
            startValue: startFilter,
            endValue: endFilter,
            applicationID: applicationIDFilter,
            messageType: messageTypeFilter,
            messageID: messageIDFilter,
            [filter]: selectedOption // Use the selected filter here
        });
    };


    const applyFilters = (filters) => {
        setFilteredData(
            responseData.filter(item =>
                (!filters.startValue || item.Start === filters.startValue?.value) &&
                (!filters.endValue || item.End === filters.endValue?.value) &&
                (!filters.applicationID || item.ApplicationID === filters.applicationID?.value) &&
                (!filters.messageType || item.MessageType === filters.messageType?.value) &&
                (!filters.messageID || item.MessageGuid === filters.messageID?.value)  // Make sure you're using the right key
            )
        );
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Start',
                accessor: 'Start',
                Cell: ({ value }) => {
                    // Add your logic here
                    if (isCustomTimeZone) {
                        // Convert `value` to the given timezone
                        return formatToGivenTimeZone(value, 'YourTimeZone'); // Replace 'YourTimeZone' with the desired timezone
                    } else {
                        // Convert `value` to browser timezone
                        return formatToBrowserTimeZone(value);
                    }
                },
            },
            {
                Header: 'End',
                accessor: 'End',
                Cell: ({ value }) => {
                    if (isCustomTimeZone) {
                        return formatToGivenTimeZone(value, 'YourTimeZone');
                    } else {
                        return formatToBrowserTimeZone(value);
                    }
                },
            },
            { Header: 'Application ID', accessor: 'ApplicationID' },
            { Header: 'Message Type', accessor: 'MessageType' },
            { Header: 'MessageGuid', accessor: 'MessageGuid' },

            // { Header: 'Link', accessor: 'Link' },
            {
                Header: 'Link',
                accessor: 'Link',
                Cell: ({ row }) => (
                    <a
                        href={row.original.Link} // Use the value from the row data
                        target="_blank" // Open in a new tab
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        Link
                    </a>
                ),
            },
        ],
        [isCustomTimeZone]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: { pageIndex: 0, pageSize },
            manualPagination: false,
        },
        usePagination
    );
    // Utility Functions
    const formatToGivenTimeZone = (date, timeZone) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone,
        };
        const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(new Date(date));
        return formattedDate.replace(/,/, ''); // Remove extra comma if any
    };

    const formatToBrowserTimeZone = (date) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(new Date(date));
        return formattedDate.replace(/,/, ''); // Remove extra comma if any
    };


    const gotoPage = (page) => {
        const conditions = {
            'DetailsPage': true,
            "PackageName": messageOverallData.PackageName,
            "IFlowName": messageOverallData.IFlowName,
            "Status": messageOverallData.Status,
            "EmailID": EmailID,
            "End": startEndTime.end,
            "Start": startEndTime.start
        };

        setCurrentPage(page);
        fetchData(conditions, currentPage, pageSize);
    };

    const handleConfirmClick = () => {
        const conditions = {
            'DetailsPage': true,
            "PackageName": messageOverallData.PackageName,
            "IFlowName": messageOverallData.IFlowName,
            "Status": messageOverallData.Status,
            "EmailID": EmailID,
            "End": startEndTime.end,
            "Start": startEndTime.start
        };

        fetchData(conditions, currentPage, pageSize);
    };

    const updatePageSize = (newSize) => {
        setPageSize(newSize);
    };
    // Date formatting function to handle condition
    const formatDateToUTC = (date, isCustomTimeZone) => {
        if (isCustomTimeZone) {
            //return formatToGivenTimeZone(date, 'YourTimeZone'); // Replace with actual time zone
        } else {
            return formatToBrowserTimeZone(date);
        }
    };
    const handleResetFilters = () => {
        setStartFilter(null);
        setEndFilter(null);
        setApplicationIDFilter(null);
        setMessageTypeFilter(null);
        setMessageIDFilter(null);
        setFilteredData(responseData); // Reset to all data
    };
    return (
        <div>
            {loading ? (
                <LoadingPopup message={message} />
            ) : (
                <>
                    <Header />
                    <SideBar onSidebarItemClick={handleSidebarItemClick} />
                    <div className="app-container">
                        <main id="main" className="main">
                            <PageTitle title={"Details Page"} subtitle={"Details"} />
                            <p className='details-datetime'>
                                <button className="global-button" onClick={handleGoBackClick}>Go Back</button>{' '}
                                From: {formatDateToUTC(startEndTime.start, isCustomTimeZone)} To: {formatDateToUTC(startEndTime.end, isCustomTimeZone)}
                            </p>
                            <div className="details-wrapper">

                                <div className='left-align-details'>
                                    <div className="details-left-side">
                                        <div className="labels-column">
                                            <div className="left-label">IFlow Name</div>
                                            <div className="left-label">Package Name</div>
                                        </div>
                                        <div className="values-column">
                                            <div className="left-value">{responseData[0].IFlowName}</div>
                                            <div className="left-value">{responseData[0].PackageName}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='right-align-details'>
                                    <div className="details-right-side">
                                        <div className="labels-column">
                                            <div className="right-label">Sender</div>
                                            <div className="right-label">Receiver</div>
                                        </div>
                                        <div className="values-column">
                                            <div className="right-value">{responseData[0].Sender}</div>
                                            <div className="right-value">{responseData[0].Receiver}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='right-align-details'>
                                    <div className="details-exright-side">
                                        <div className="labels-column">
                                            <div className="right-label">Status</div>
                                            <div className="right-label">Total Count</div>
                                        </div>
                                        <div className="values-column">
                                            <div className="right-value">{header}</div>
                                            <div className="right-value">{totalRecords}
                                                {/* {header == "COMPLETED"
                                                    ? responseData[0].Successful // Replace with your value for Successful
                                                    : header == "FAILED"
                                                        ? responseData[0].Error // Replace with your value for Failed
                                                        : header == "RETRY"
                                                            ? responseData[0].Retry // Replace with your value for Retry
                                                            : 'N/A' // Optional: Add a fallback value if none of the conditions match */}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            {/* Table */}
                            <table {...getTableProps()} className="monitoring-table">
                                <thead>
                                    {/* Filter Row */}
                                    <tr className="filter-row">
                                        <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('Start')}
                                                value={startFilter}
                                                onChange={handleFilterChange('startValue', setStartFilter)}
                                                placeholder="Start"
                                                className="details-dropdown-filter"
                                            />
                                        </th>
                                        <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('End')}
                                                value={endFilter}
                                                onChange={handleFilterChange('endValue', setEndFilter)}
                                                placeholder="End"
                                                className="details-dropdown-filter"
                                            />
                                        </th>
                                        <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('ApplicationID')}
                                                value={applicationIDFilter}
                                                onChange={handleFilterChange('applicationID', setApplicationIDFilter)}
                                                placeholder="ApplicationID"
                                                className="details-dropdown-filter"
                                            />
                                        </th>
                                        <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('MessageType')}
                                                value={messageTypeFilter}
                                                onChange={handleFilterChange('messageType', setMessageTypeFilter)}
                                                placeholder="Message Type"
                                                className="details-dropdown-filter"
                                            />
                                        </th>

                                        <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('MessageGuid')}
                                                value={messageIDFilter}
                                                onChange={handleFilterChange('messageID', setMessageIDFilter)}
                                                placeholder="Message Guid"
                                                className="details-dropdown-filter"
                                            />
                                        </th>
                                        <th className="column-header">
                                            <button className="global-button" onClick={handleResetFilters}>Reset</button>{' '}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td colSpan={columns.length}>
                                            <hr style={{ margin: '-8px -8px', borderTop: '3px solid #ccc' }} />

                                        </td>
                                    </tr>
                                    {/* Column Headers Row */}
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps()} key={column.id} className="column-header">
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody {...getTableBodyProps()}>
                                    {page.length === 0 ? (
                                        <tr>
                                            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                                No data found.
                                            </td>
                                        </tr>
                                    ) : (
                                        page.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    key={row.id}
                                                    className={`${i % 2 === 0 ? 'row-grey' : 'row-white'} ${i === 2 ? 'custom-row-height' : ''}`}
                                                >
                                                    {row.cells.map((cell) => (
                                                        <td {...cell.getCellProps()} key={cell.column.id}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                            <div className="details-table-top-left">
                                {/* Pagination controls */}
                                <div className="details-pagination">

                                    <div className="pagination-controls">
                                        <button onClick={() => gotoPage(1)} disabled={currentPage === 1}>
                                            <i className="fas fa-angle-double-left"></i>
                                        </button>
                                        <button onClick={() => gotoPage(currentPage - 1)} disabled={currentPage === 1}>
                                            <i className="fas fa-angle-left"></i>
                                        </button>
                                        <span>
                                            Page{' '}
                                            {currentPage} of {totalPages}
                                        </span>
                                        <button onClick={() => gotoPage(currentPage + 1)} disabled={currentPage === totalPages}>
                                            <i className="fas fa-angle-right"></i>
                                        </button>
                                        <button onClick={() => gotoPage(totalPages)} disabled={currentPage === totalPages}>
                                            <i className="fas fa-angle-double-right"></i>
                                        </button>
                                    </div>
                                    <div className="details-page-size-selector">
                                        <label>
                                            <select value={pageSize} onChange={(e) => updatePageSize(Number(e.target.value))}>
                                                {[25, 50, 75, 100].map(size => (
                                                    <option key={size} value={size}>
                                                        {size}
                                                    </option>
                                                ))}
                                            </select>{' '}
                                        </label>
                                        <button className="global-button" onClick={handleConfirmClick}>Refresh</button>{' '}
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                    <Footer />
                    <BackToTop />
                </>
            )
            }
        </div >
    );
};

export default DetailsPage;

