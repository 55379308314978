import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

// const NotFound = () => {
//     return (
//         <div className="not-found">
//             <div className="content">
//                 <h1>404</h1>
//                 <h2>Oops! Page Not Found</h2>
//                 <p>Sorry, the page you’re looking for doesn’t exist or has been moved.</p>
//                 <div className="notfound-link">
//                     <p> <Link to="/" className="btn-submit">Go to Homepage</Link></p>
//                 </div>
//             </div>
//         </div>
//     );
// };


const NotFound = () => {
    return (
        <div className="not-found">
            <div className="content">
                <div className="custom-404-container">
                    <div className="custom-404">404</div>
                    <h2>Oops! Page Not Found</h2>
                    <div className="error-message"><p>Sorry, the page you’re looking for doesn’t exist or has been moved.</p></div>
                    <Link to="/" className="btn-submit">Go to Homepage</Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;