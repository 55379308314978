import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LoginPage from './Pages/Login/LoginPage';
import RegistrationPage from './Pages/Registration/RegistrationPage';
import { AlertProvider } from './ProHelpers/AlertContext';
import RegistrationComplete from './Pages/Registration/RegistrationComplete';
import VerificationPage from './Pages/Registration/VerificationPage';
import VerificationCompleted from './Pages/Registration/VerificationCompleted'

import ForgotPassword from './Pages/ForgetPassword/ForgotPassword'
import ForgotVerificationPage from './Pages/ForgetPassword/ForgotVerificationPage';
import ForgotPasswordSet from './Pages/ForgetPassword/ForgotPasswordSet';
import UnderConstruction from './Pages/UnderConstruction/UnderConstruction'
import NotFound from './Pages/NotFound/NotFound'
//import OverView from './Components/Dashboard/OverView';
import DashboardLayout from './Components/Dashboard/DashboardLayout'
import DetailsPage from './Components/Dashboard/DetailsPage'
import DetailsAllPage from './Components/Dashboard/DetailsAllPage'
// import Dashboard from './Components/Dashboard/Dashboard';
import MonitoringOverall from './Components/Dashboard/MonitoringOverall';
import MonitoringFailure from './Components/Dashboard/MonitoringFailure';
import JobOverall from './Components/Dashboard/JobOverall';
import JobsFailure from './Components/Dashboard/JobsFailure';
import BrandingLogo from './Components/Dashboard/BrandingLogo';
import UserProfile from './Components/Dashboard/UserProfile';
import SystemConnectivity from './Components/Dashboard/SystemConnectivity';
import EndPointsConnectivity from './Components/Dashboard/EndPoints';
import CompanyUsers from './Components/Dashboard/CompanyUsers';
import Dashboard from './Components/Dashboard/Dashboard';
// import MonitoringFailure from './Components/Dashboard/MonitoringFailure';
// import SecurityInvite from './Components/Dashboard/SecurityInvite';
// import SecurityDelete from './Components/Dashboard/SecurityDelete';
// import SecurityRoleAssignment from './Components/Dashboard/SecurityRoleAssignment';
// import SystemAuditMessages from './Components/Dashboard/SystemAuditMessages';
// import SystemAuditSecurity from './Components/Dashboard/SystemAuditSecurity';
// import SettingsInbound from './Components/Dashboard/SettingsInbound';
// import SettingsOutbound from './Components/Dashboard/SettingsOutbound';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  }, {
    path: "/register",
    element: <RegistrationPage />,
  }, {
    path: "/registrationcompleted",
    element: <RegistrationComplete />,
  }, {
    path: "/verification",
    element: <VerificationPage />,
  }, {
    path: "/verificationcompleted",
    element: <VerificationCompleted />,
  },
  // {
  //   path: "/Dashboard",
  //   element: <Dashboard />,
  // },
  {
    path: "/overview",
    element: <Dashboard />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  }, {
    path: "/forgotverification",
    element: <ForgotVerificationPage />,
  }, {
    path: "/forgotpasswordset",
    element: <ForgotPasswordSet />
  }, {
    path: "/underconstruction",
    element: <UnderConstruction />
  }, {
    path: "/NotFound",
    element: <NotFound />
  },
  {
    path: "/monitoring/overall",
    element: <MonitoringOverall />
  },
  {
    path: "/monitoring/failure-retried",
    element: <MonitoringFailure />
  },
  {
    path: "/monitoring/jobs-overall",
    element: <JobOverall />
  },
  {
    path: "/monitoring/jobs-failure",
    element: <JobsFailure />
  },
  {
    path: "/monitoring/overall/details",
    element: <DetailsPage />
  },
  {
    path: "/monitoring/overall/detailsall",
    element: <DetailsAllPage />
  },
  {
    path: "/branding",
    element: <BrandingLogo />
  },
  {
    path: "/userprofile",
    element: <UserProfile />
  },
  {
    path: "/connectivity/destination",
    element: <SystemConnectivity />
  },
  {
    path: "/connectivity/endpoint",
    element: <EndPointsConnectivity />
  },
  {
    path: "/security/users",
    element: <CompanyUsers />
  },
  {
    path: "*",
    element: <NotFound />,
  },

  //  {
  //   path: "IntegrationService",
  //   element: <IntegrationSolution />,
  // }, {
  //   path: "AutomationTools",
  //   element: <AutomationTools />,
  // }, {
  //   path: "ComprehensiveResourcing",
  //   element: <ComprehensiveResourcing />,
  // }
]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AlertProvider>
      <RouterProvider router={router} />
    </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
