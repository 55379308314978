import React from 'react';
import './sideBar.css';
import { Link } from 'react-router-dom';
import Monitoring from '../../Resource/Monitoring.png';
import SecurityAudit from '../../Resource/SecurityAudit.png';
import Settings from '../../Resource/Settings.png';


function SideBar({ onSidebarItemClick }) {
  const handleSidebarItemClick = () => {
    // Call the function passed via props to handle resetting
    if (onSidebarItemClick) onSidebarItemClick();
  };

  return (
    <aside id="dashboard-sidebar" className="dashboard-sidebar">
      <ul className="dashboard-sidebar-nav" id="dashboard-sidebar-nav">
        <li className="nav-item">
          <Link to="/overview" className="nav-link" onClick={handleSidebarItemClick}>
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#components-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <img src={Monitoring} alt="Monitoring Icon" className="sidebar-icon" />
            <span>Monitoring</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="components-nav" className="nav-content collapse" data-bs-parent="#dashboard-sidebar-nav">
            <li className="nav-heading">Messages</li>
            <li>
              <Link to="/monitoring/overall" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>Overview</span>
              </Link>
            </li>
            <li>
              <Link to="/monitoring/failure-retried" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>Failure-Retry</span>
              </Link>
            </li>
          </ul>
          <ul id="components-nav" className="nav-content collapse" data-bs-parent="#dashboard-sidebar-nav">
            <li className="nav-heading">Jobs</li>
            <li>
              <Link to="/monitoring/jobs-overall" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>Overview</span>
              </Link>
            </li>
            <li>
              <Link to="/monitoring/jobs-failure" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>Failure</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#forms-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <img src={SecurityAudit} alt="Security Audit Icon" className="sidebar-icon" />
            <span>Security & Audit</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="forms-nav" className="nav-content collapse" data-bs-parent="#dashboard-sidebar-nav">
            <li className="nav-heading">Users</li>
            <li>
              <Link to="/security/users" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>Users</span>
              </Link>
            </li>
            <li className="nav-heading">Audit</li>
            <li>
              <Link to="/audit/messages" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>System</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#tables-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <img src={Settings} alt="Settings Icon" className="sidebar-icon" />
            <span>Settings</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="tables-nav" className="nav-content collapse" data-bs-parent="#dashboard-sidebar-nav">
            <li>
              <Link to="/branding" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>Branding & Logo</span>
              </Link>
            </li>
            <li>
              <Link to="/settings/outbound" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>Features</span>
              </Link>
            </li>
            <li className="nav-heading">Connectivity</li>
            <li>
              <Link to="/connectivity/destination" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>Destination</span>
              </Link>
            </li>
            <li>
              <Link to="/connectivity/endpoint" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>Endpoint</span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </aside>
  );
}

export default SideBar;
