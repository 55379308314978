import React, { useEffect, useState } from 'react';
import useCheckAuth from '../../ProHelpers/CheckAuth'
import Cards from './DashboardCards';
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';
import LoadingPopup from '../../ProHelpers/LoadingPopup';

function Dashboard() {
    useCheckAuth();
    const CustomerID = sessionStorage.getItem('CustomerID');
    const EmailID = sessionStorage.getItem('EmailID');
    const UserName = sessionStorage.getItem('UserName');
    const AccountType = sessionStorage.getItem('AccountType');
    const [loading, setLoading] = useState(false);
    const [timeRange, setTimeRange] = useState("");
    const [startEndTime, setStartEndTime] = useState(null);
    const [message, setMessage] = useState("Please wait, loading...");
    const handleSidebarItemClick = () => {
        // Optional: Clear localStorage if you want to remove the saved range
        //localStorage.removeItem("timeRange");

        // Reset the timeRange and date-time range
        setTimeRange("lastDay");
        calculateTimeRange("lastDay");
    };
    const calculateTimeRange = (selectedRange) => {
        const end = new Date(); // current date and time
        let start;

        switch (selectedRange) {
            case "lastHour":
                start = new Date(end.getTime() - 60 * 60 * 1000); // Subtract 1 hour in milliseconds
                break;
            case "lastDay":
                start = new Date(end.getTime() - 24 * 60 * 60 * 1000); // Subtract 1 day in milliseconds
                break;
            case "lastWeek":
                start = new Date(end.getTime() - 24 * 7 * 60 * 60 * 1000); // Subtract 1 day in milliseconds
                break;
            case "lastMonth":
                // Handle month subtraction properly and account for day overflow
                const previousMonth = new Date(end);
                previousMonth.setMonth(end.getMonth() - 1); // Move to the same day of the previous month
                if (previousMonth.getMonth() === end.getMonth()) {
                    // This means the day in the previous month doesn't exist; adjust to the last day
                    previousMonth.setDate(0);
                }
                start = new Date(previousMonth);
                break;
            case "thisMonth":
                start = new Date(end.getFullYear(), end.getMonth(), 1, 0, 0, 0, 0); // Start of the current month
                break;
            default:
                start = null;
                break;
        }
        setStartEndTime((prev) => ({
            ...prev,
            start: start ? start.toISOString() : null,
            end: end.toISOString(),
        }));
        if (selectedRange) {
            localStorage.setItem("timeRange", selectedRange);
        }
    };

    return (<div>
        {loading ? (
            <LoadingPopup message={message} />
        ) : (
            <>
                <Header />
                <SideBar onSidebarItemClick={handleSidebarItemClick} />
                <div className="app-container">
                    <main id="main" className="main">
                        <PageTitle title={"Dashboard"} subtitle={"Dashboard"} />
                        <section className="section dashboard">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="row">
                                        <Cards />
                                        Welcome, {UserName} <br></br>
                                        Account Type: {AccountType}<br></br>
                                        CustomerID : {CustomerID}
                                        <div className="col-12">
                                            {/* <Reports /> */}
                                        </div>
                                        <div className="col-12">
                                            {/* <RecentSales /> */}
                                        </div>
                                        <div className="col-12">
                                            {/* <TopSelling /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    {/* <RecentActivity />
          <BudgetReport />
          <WebTraffic />
          <News /> */}
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
                <Footer />
                <BackToTop />
            </>
        )}
    </div>
    )
}

export default Dashboard