import React from 'react';
import './logo.css';
import Logo from '../../ProHelpers/Logo'
import { Link } from 'react-router-dom';
import profileImg from '../../Resource/logo.png';

function DashboardLogo() {
  const handleToggleSideBar = () => {
    document.body.classList.toggle('toggle-sidebar');
  };
  return (
    <div className="d-flex align-items-center justify-content-between">
      <Link to="/overview" className="dashboard-logo d-flex align-items-center">
        {/* <img src="" alt=""/> */}
        <Logo className="dashboard-logo-small" />

      </Link>
      <i
        className="bi bi-list toggle-sidebar-btn"
        onClick={handleToggleSideBar}
      ></i>
    </div>
  );
}

export default DashboardLogo;
