import React from 'react'
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';


function JobsFailure() {
    return (
        <>
            <Header />
            <SideBar />
            <div className="app-container">
                <main id="main" className="main">
                    <PageTitle title={"Job Failure"} subtitle={"Message Overall"} />
                    <div>Jobs Failure</div>
                </main>
            </div>
            <Footer />
            <BackToTop />
        </>

    )
}

export default JobsFailure