import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';
import './MonitoringOverall.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Select from 'react-select';
import DatePicker from 'react-datepicker'; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker CSS
import { useAlert } from '../../ProHelpers/AlertContext';
import { useNavigate } from 'react-router-dom';
import { formatDateToUTC } from '../../ProHelpers/DateUtils'
import Header from './Header';
import SideBar from './SideBar';
import './main.css';
import PageTitle from './PageTitle';
import BackToTop from './BackToTop';
import Footer from './Footer';

const MonitoringOverall = () => {
    const EmailID = sessionStorage.getItem('EmailID');
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [responseData, setresponseData] = useState([]);
    const [message, setMessage] = useState("Please wait, loading...");
    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
    const [startEndTime, setStartEndTime] = useState({ start: new Date(), end: new Date() });
    const [timeRange, setTimeRange] = useState("");

    const [filteredData, setFilteredData] = useState([]);
    const [iFlowNameFilter, setIFlowNameFilter] = useState(null);
    const [packageNameFilter, setPackageNameFilter] = useState(null);
    const [senderFilter, setSenderFilter] = useState(null);
    const [receiverFilter, setReceiverFilter] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(25); // items per page, can also be a variable


    // const formatDate = (date, forAPI = false) => {
    //     if (!date) return ""; // Handle null or undefined
    //     const d = new Date(date);
    //     const pad = (num) => String(num).padStart(2, '0'); // Helper function to pad numbers

    //     if (forAPI) {
    //         // Format as UTC time with "Z" to indicate UTC
    //         const yearUTC = d.getUTCFullYear();
    //         const monthUTC = pad(d.getUTCMonth() + 1); // Months are zero-indexed
    //         const dayUTC = pad(d.getUTCDate());
    //         const hoursUTC = pad(d.getUTCHours());
    //         const minutesUTC = pad(d.getUTCMinutes());
    //         const secondsUTC = pad(d.getUTCSeconds());
    //         const millisecondsUTC = String(d.getUTCMilliseconds()).padStart(3, '0'); // Pad milliseconds to 3 digits
    //         return `${yearUTC}-${monthUTC}-${dayUTC}T${hoursUTC}:${minutesUTC}:${secondsUTC}.${millisecondsUTC}Z`;
    //     } else {
    //         // Format as local system time
    //         const year = d.getFullYear();
    //         const month = pad(d.getMonth() + 1); // Months are zero-indexed
    //         const day = pad(d.getDate());
    //         const hours = pad(d.getHours());
    //         const minutes = pad(d.getMinutes());
    //         const seconds = pad(d.getSeconds());
    //         return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Local time in readable format
    //     }
    // };
    const handleSidebarItemClick = () => {
        // Optional: Clear localStorage if you want to remove the saved range
        //localStorage.removeItem("timeRange");

        // Reset the timeRange and date-time range
        setTimeRange("lastDay");
        calculateTimeRange("lastDay");
    };
    const calculateTimeRange = (selectedRange) => {
        const end = new Date(); // current date and time
        let start;

        switch (selectedRange) {
            case "lastHour":
                start = new Date(end.getTime() - 60 * 60 * 1000); // Subtract 1 hour in milliseconds
                break;
            case "lastDay":
                start = new Date(end.getTime() - 24 * 60 * 60 * 1000); // Subtract 1 day in milliseconds
                break;
            case "lastWeek":
                start = new Date(end.getTime() - 24 * 7 * 60 * 60 * 1000); // Subtract 1 day in milliseconds
                break;
            case "lastMonth":
                // Handle month subtraction properly and account for day overflow
                const previousMonth = new Date(end);
                previousMonth.setMonth(end.getMonth() - 1); // Move to the same day of the previous month
                if (previousMonth.getMonth() === end.getMonth()) {
                    // This means the day in the previous month doesn't exist; adjust to the last day
                    previousMonth.setDate(0);
                }
                start = new Date(previousMonth);
                break;
            case "thisMonth":
                start = new Date(end.getFullYear(), end.getMonth(), 1, 0, 0, 0, 0); // Start of the current month
                break;
            default:
                start = null;
                break;
        }
        setStartEndTime((prev) => ({
            ...prev,
            start: start ? start.toISOString() : null,
            end: end.toISOString(),
        }));
        if (selectedRange) {
            localStorage.setItem("timeRange", selectedRange);
        }
    };
    const gotoPage = (page) => {
        setCurrentPage(page);
        fetchData({
            'DetailsPage': false,
            'EmailID': EmailID,
            'End': formatDateToUTC(startEndTime.end, true),
            'Start': formatDateToUTC(startEndTime.start, true)
        }, page, pageSize);
    };




    const updatePageSize = (newSize) => {
        setPageSize(newSize);
    };


    const handleTimeRangeChange = (event) => {
        const selectedRange = event.target.value;
        setTimeRange(selectedRange);

        if (selectedRange === "custom") {
            setShowCustomDatePicker(true);
        } else {
            setShowCustomDatePicker(false);
            setFromDate('');
            setToDate('');
            calculateTimeRange(selectedRange);
        }
    };



    useEffect(() => {
        const storedTimeRange = localStorage.getItem("timeRange");

        if (storedTimeRange) {
            // Set the timeRange from localStorage if it exists
            setTimeRange(storedTimeRange);
            calculateTimeRange(storedTimeRange);
        } else if (timeRange === "custom" && (!fromDate || !toDate)) {
            // Fallback to custom date-time range logic if no stored value exists
            const now = new Date().toISOString();
            setFromDate(now);
            setToDate(now);
        }
    }, [timeRange, fromDate, toDate]);

    useEffect(() => {
        const storedTimeRange = localStorage.getItem("timeRange") || "lastDay"; // Default to "lastDay" if not present
        setTimeRange(storedTimeRange);
        calculateTimeRange(timeRange); // Will calculate for the 'lastDay' range
    }, []);  // This runs only once, on component mount

    useEffect(() => {
        if (startEndTime.start && startEndTime.end) {
            const startFormatted = formatDateToUTC(startEndTime.start, true);
            const endFormatted = formatDateToUTC(startEndTime.end, true);

            console.log("Start Datetime API (Initial): ", startFormatted);
            console.log("End Datetime API (Initial): ", endFormatted);

            // Fetch data based on the calculated start and end times
            fetchData({
                'DetailsPage': false,
                'EmailID': EmailID,
                'End': endFormatted,
                'Start': startFormatted,
                'Page': currentPage,
                'PageSize': pageSize
            }, currentPage, pageSize);
            //setLoading(false); // Data fetching is done, no longer loading
        }
    }, [startEndTime, currentPage, pageSize]);  // Added currentPage and pageSize to the dependency array


    const fetchData = async (condition = {}, Page, PageSize) => {
        const requestBody = { ...condition, Page, PageSize };

        setMessage("Please Wait, Loading...");
        console.log("Request Body:", requestBody);
        setLoading(true);
        try {
            const response = await AxiosInstance.post('/message-processinglog', requestBody);

            if (response.data.status === "success" && response.data.message && response.data.message.data.length > 0) {
                setresponseData(response.data.message.data);
                setFilteredData(response.data.message.data);

                // Assuming the server returns totalItems and pageSize
                setTotalPages(Math.ceil(response.data.message.total_records / pageSize));
            } else {
                setresponseData([]);
                setFilteredData([]);
                setTotalPages(1); // Set to 1 if no data is available
            }

            console.log('Response Data:', response.data);
        } catch (err) {
            console.error('Error fetching data:', err);
            setresponseData([]);
            setFilteredData([]);
            setTotalPages(1);
        } finally {
            setLoading(false);
        }
    };

    // Unique dropdown options
    const getUniqueOptions = (key) => {
        return [...new Set(responseData.map(item => item[key]))]
            .filter(item => item) // remove empty values
            .map(item => ({ value: item, label: item }));
    };

    // Handle dropdown changes
    const handleFilterChange = (filter, setFilter) => (selectedOption) => {
        setFilter(selectedOption);
        applyFilters({
            iFlowName: iFlowNameFilter,
            packageName: packageNameFilter,
            sender: senderFilter,
            receiver: receiverFilter,
            [filter]: selectedOption
        });
    };

    const applyFilters = (filters) => {
        setFilteredData(
            responseData.filter(item =>
                (!filters.iFlowName || item.IFlowName === filters.iFlowName?.value) &&
                (!filters.packageName || item.PackageName === filters.packageName?.value) &&
                (!filters.sender || item.Sender === filters.sender?.value) &&
                (!filters.receiver || item.Receiver === filters.receiver?.value)
            )
        );
    };

    const columns = React.useMemo(
        () => [
            { Header: 'Package Name', accessor: 'PackageName' },
            { Header: 'IFlow Name', accessor: 'IFlowName' },
            { Header: 'Sender', accessor: 'Sender' },
            { Header: 'Receiver', accessor: 'Receiver' },
            {
                Header: 'Successful',
                accessor: 'Successful',
                Cell: ({ row }) => (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault(); // Prevents default link behavior
                            navigate('/monitoring/overall/details', {
                                state: {
                                    messageOverallData: row.original,
                                    header: 'COMPLETED',
                                    dateRange: { startEndTime }
                                }
                            });
                        }}
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        {row.values.Successful}
                    </a>
                ),
            },
            // { Header: 'Retry', accessor: 'Retry' },
            // { Header: 'Error', accessor: 'Error' },
            {
                Header: 'Retry',
                accessor: 'Retry',
                Cell: ({ row }) => {
                    const retryValue = row.values.Retry; // Extract Retry value
                    return retryValue > 0 ? (
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault(); // Prevents default link behavior
                                navigate('/monitoring/overall/details', {
                                    state: {
                                        messageOverallData: row.original,
                                        header: 'RETRY',
                                        dateRange: { startEndTime },
                                    },
                                });
                            }}
                            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            {retryValue}
                        </a>
                    ) : (
                        <span>{retryValue}</span> // Display plain text for value 0
                    );
                },
            },
            {
                Header: 'Error',
                accessor: 'Error',
                Cell: ({ row }) => {
                    const errorValue = row.values.Error; // Extract Retry value
                    return errorValue > 0 ? (
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault(); // Prevents default link behavior
                                navigate('/monitoring/overall/details', {
                                    state: {
                                        messageOverallData: row.original,
                                        header: 'FAILED',
                                        dateRange: { startEndTime },
                                    },
                                });
                            }}
                            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            {errorValue}
                        </a>
                    ) : (
                        <span>{errorValue}</span> // Display plain text for value 0
                    );
                },
            },
            // {
            //     Header: 'Error',
            //     accessor: 'Error',
            //     Cell: ({ row }) => (
            //         <a
            //             href="#"
            //             onClick={(e) => {
            //                 e.preventDefault(); // Prevents default link behavior
            //                 navigate('/monitoring/overall/details', {
            //                     state: {
            //                         messageOverallData: row.original,
            //                         header: 'FAILED',
            //                         dateRange: { startEndTime }
            //                     }
            //                 });
            //             }}
            //             style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
            //         >
            //             {row.values.Error}
            //         </a>
            //     ),
            // },
            // { Header: 'Total', accessor: 'Total' },
            {
                Header: 'Total',
                accessor: 'Total',
                Cell: ({ row }) => (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault(); // Prevents default link behavior
                            navigate('/monitoring/overall/detailsall', {
                                state: {
                                    messageOverallData: row.original,
                                    header: 'Total',
                                    dateRange: { startEndTime }
                                }
                            });
                        }}
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        {row.values.Total}
                    </a>
                ),
            },
        ],
        [navigate, filteredData]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: { pageIndex: 0, pageSize },
            manualPagination: false,
        },
        usePagination
    );


    const handleResetFilters = () => {
        setIFlowNameFilter(null);
        setPackageNameFilter(null);
        setSenderFilter(null);
        setReceiverFilter(null);
        setFilteredData(responseData); // Reset to all data
    };


    const handleConfirmClick = () => {
        // Use the custom date range if in 'custom' mode
        let start_date = startEndTime.start;
        let end_date = startEndTime.end;

        if (timeRange === "custom") {
            start_date = fromDate || start_date; // Use fromDate if set, fallback to existing start
            end_date = toDate || end_date; // Use toDate if set, fallback to existing end
        }

        // Format dates for API
        const formattedStart = formatDateToUTC(start_date, true);
        const formattedEnd = formatDateToUTC(end_date, true);
        console.log("Start Datetime Clicked : ", formattedStart);
        console.log("End Datetime Clicked : ", formattedEnd);

        const conditions = {
            'DetailsPage': false,
            'EmailID': EmailID,
            'End': formattedEnd,
            'Start': formattedStart
        };
        fetchData(conditions, currentPage, pageSize);
    };

    return (
        <div>
            {loading ? (
                <LoadingPopup message={message} />
            ) : (
                <>
                    <Header />
                    <SideBar onSidebarItemClick={handleSidebarItemClick} />
                    <div className="app-container">
                        <main id="main" className="main">
                            <PageTitle title={"Monitoring"} subtitle={"Message Overall"} />
                            <div className="monitoring-page">

                            </div>
                            <div className="table-top-right">
                                <div className="left-align">
                                    <div className="time-range-selector">
                                        <label>
                                            Time:{' '}
                                            <select value={timeRange} onChange={handleTimeRangeChange}>
                                                <option value="lastHour">Last 1 Hour</option>
                                                <option value="lastDay">Last 1 Day</option>
                                                <option value="lastWeek">Last Week</option>
                                                <option value="lastMonth">Last Month</option>
                                                <option value="thisMonth">This Month</option>
                                                <option value="custom">Custom</option>
                                            </select>
                                        </label>
                                    </div>

                                    {showCustomDatePicker ? (
                                        <div className="custom-date-picker">
                                            <label>
                                                From:  &nbsp;<input type="datetime-local"
                                                    value={formatDateToUTC(fromDate)}
                                                    onChange={(e) => setFromDate(e.target.value)}
                                                    max={new Date().toISOString().slice(0, 16)} />
                                            </label>
                                            <label>
                                                To:  &nbsp;<input type="datetime-local"
                                                    value={formatDateToUTC(toDate)}
                                                    onChange={(e) => setToDate(e.target.value)}
                                                    max={new Date().toISOString().slice(0, 16)} />
                                            </label>

                                        </div>
                                    ) : (
                                        <div className="selected-range">
                                            {startEndTime.start && startEndTime.end && (
                                                <p><b>Start</b>: {formatDateToUTC(startEndTime.start).toLocaleString()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>End</b>: {formatDateToUTC(startEndTime.end).toLocaleString()}</p>
                                            )}
                                        </div>)}
                                    <button className="global-button" onClick={handleConfirmClick}>Confirm</button>
                                    <button className="global-button" onClick={handleResetFilters}>Reset Filter</button>
                                </div>

                                {/* <div className="page-size-selector">
                                    <label>
                                        <select value={pageSize} onChange={(e) => updatePageSize(Number(e.target.value))}>
                                            {[25, 50, 75, 100].map(size => (
                                                <option key={size} value={size}>
                                                    {size}
                                                </option>
                                            ))}
                                        </select>{' '}
                                    </label>
                                    <button className="global-button" onClick={handleConfirmClick}>Refresh</button>{' '}
                                </div> */}
                            </div>

                            {/* Table */}
                            <table {...getTableProps()} className="monitoring-table">
                                <thead>
                                    {/* Filter Row */}
                                    <tr className="filter-row">
                                        <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('PackageName')}
                                                value={packageNameFilter}
                                                onChange={handleFilterChange('packageName', setPackageNameFilter)}
                                                placeholder="Package Name"
                                                className="dropdown-filter"
                                            />
                                        </th>
                                        <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('IFlowName')}
                                                value={iFlowNameFilter}
                                                onChange={handleFilterChange('iFlowName', setIFlowNameFilter)}
                                                placeholder="IFlow Name"
                                                className="dropdown-filter"
                                            />
                                        </th>
                                        <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('Sender')}
                                                value={senderFilter}
                                                onChange={handleFilterChange('sender', setSenderFilter)}
                                                placeholder="Sender"
                                                className="dropdown-filter"
                                            />
                                        </th>
                                        <th className="column-header">
                                            <Select
                                                options={getUniqueOptions('Receiver')}
                                                value={receiverFilter}
                                                onChange={handleFilterChange('receiver', setReceiverFilter)}
                                                placeholder="Receiver"
                                                className="dropdown-filter"
                                            />
                                        </th>
                                    </tr>

                                    {/* Column Headers Row */}
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps()} key={column.id} className="column-header">
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody {...getTableBodyProps()}>
                                    {page.length === 0 ? (
                                        <tr>
                                            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                                No data found.
                                            </td>
                                        </tr>
                                    ) : (
                                        page.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    key={row.id}
                                                    className={`${i % 2 === 0 ? 'row-grey' : 'row-white'} ${i === 2 ? 'custom-row-height' : ''}`}
                                                >
                                                    {row.cells.map((cell) => (
                                                        <td {...cell.getCellProps()} key={cell.column.id}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>




                            <div className="details-table-top-left">
                                {/* Pagination controls */}
                                <div className="details-pagination">

                                    <div className="pagination-controls">
                                        <button onClick={() => gotoPage(1)} disabled={currentPage === 1}>
                                            <i className="fas fa-angle-double-left"></i>
                                        </button>
                                        <button onClick={() => gotoPage(currentPage - 1)} disabled={currentPage === 1}>
                                            <i className="fas fa-angle-left"></i>
                                        </button>
                                        <span>
                                            Page{' '}
                                            {currentPage} of {totalPages}
                                        </span>
                                        <button onClick={() => gotoPage(currentPage + 1)} disabled={currentPage === totalPages}>
                                            <i className="fas fa-angle-right"></i>
                                        </button>
                                        <button onClick={() => gotoPage(totalPages)} disabled={currentPage === totalPages}>
                                            <i className="fas fa-angle-double-right"></i>
                                        </button>
                                    </div>
                                    <div className="details-page-size-selector">
                                        <label>
                                            <select value={pageSize} onChange={(e) => updatePageSize(Number(e.target.value))}>
                                                {[25, 50, 75, 100].map(size => (
                                                    <option key={size} value={size}>
                                                        {size}
                                                    </option>
                                                ))}
                                            </select>{' '}
                                        </label>
                                        <button className="global-button" onClick={handleConfirmClick}>Refresh</button>{' '}
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                    <Footer />
                    <BackToTop />
                </>
            )
            }
        </div >
    );
};

export default MonitoringOverall;
